import { Tabs, Tab } from 'react-bootstrap'
import Keep from '../artifacts/Keep.json'
import React, { Component } from 'react';
import Token from '../artifacts/Token.json'
import logo_png from '../blkc-logo.png';
import Web3 from 'web3';
import './App.css';

class App extends Component {

  async componentWillMount() {
    await this.loadBlockchainData(this.props.dispatch)
  }

  async loadBlockchainData(dispatch) {

    //check if MetaMask exists
    if (typeof window.ethereum != 'undefined') {
      const web3 = new Web3(window.ethereum)
      const netId = await web3.eth.net.getId()
      let accounts = await web3.eth.getAccounts()
      console.log("NewID: ", netId)
      console.log("Account Address: ", accounts[0])


      if (typeof accounts[0] != 'undefined') {
        let weibalance = await web3.eth.getBalance(accounts[0])
        let balance = web3.utils.fromWei(weibalance)
        console.log("Balance: ", balance)
        //assign to values to variables: web3, netId, accounts
        this.setState({ account: accounts[0], balance: balance, web3: web3 })
  
      } else {
        window.alert('Please login to MetaMask')
      }

      // load contracts Token and Keep Contracts
      try {
        const tokenAddress = Token.networks[netId].address
        const keepAddress = Keep.networks[netId].address
        const tokenContract = new web3.eth.Contract(Token.abi, tokenAddress)
        const keepContract = new web3.eth.Contract(Keep.abi, keepAddress)
        this.setState({ token: tokenContract, keep: keepContract, keepAddress: keepAddress })
      } catch (e) {
        console.log('Error', e)
        window.alert('Contracts not deployed to the current network.')
      }

    } else {
      window.alert('Please install MetaMask')
    }
  }

  async deposit(amount) {
    console.log("Depositing wei amount: ", amount);
    let payload = {
      value: amount.toString(),
      from: this.state.account
    };

    //check if this.state.keep is ok
    if (typeof this.state.keep !== 'undefined') {

      try  {
        await this.state.keep.methods.deposit().send(payload);
      } catch (e) {
        console.log('Error, deposit: ', e);
      }
    }
  }

  async withdraw(e) {
    e.preventDefault()
    if (typeof this.state.keep !== 'undefined') {
      let payload = { from: this.state.account };
      try  {
        await this.state.keep.methods.withdraw().send(payload);
      } catch (e) {
        console.log('Error, withdraw: ', e);
      }
    }
    //prevent button from default click
    //check if this.state.keep is ok
    //in try block call keep withdraw();
  }

  constructor(props) {
    super(props)
    this.state = {
      web3: 'undefined',
      account: '',
      token: null,
      keep: null,
      balance: 0,
      keepAddress: null
    }
  }

  render() {
    return (
      <div className='text-monospace'>
        <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
          <a
            className="navbar-brand col-sm-3 col-md-2 mr-0"
            href="https://keep.blackcat.thkpool.com"
            target="_blank"
            rel="noopener noreferrer"
          >
        <img src={logo_png} className="App-logo" alt="logo" height="32"/>
          <b> Black Cat Keep</b>
        </a>
        </nav>
        <div className="container-fluid mt-5 text-center">
        <br></br>
          <h4>Welcome to Black Cat Keep</h4>
          <h3>{this.state.account}</h3>
          <h2>{this.state.balance} ETH</h2>
          <br></br>
          <div className="row">
            <main role="main" className="col-lg-12 d-flex text-center">
              <div className="content mr-auto ml-auto">
              <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example">
                <Tab eventKey="deposit" title="Deposit">
                  <div>
                    <br></br>
                    How much do you want to deposit?
                    <br></br>
                    (min. amount is 0.01 ETH)
                    <br></br>
                    <form onSubmit={(e) => {
                      e.preventDefault()
                      let amount = this.depositAmount.value
                      amount = amount * 10**18 //eth to wei
                      this.deposit(amount)
                    }}>
                      <div className='form-group mr-sm-2'>
                        <br></br>
                        <input
                          id='depositAmount'
                          step='0.01'
                          min='0.01'
                          type='number'
                          className='form-control form-control-md'
                          placeholder='amount...'
                          required
                          ref={(input) => { this.depositAmount = input }}
                          ></input>
                      </div>
                      <button type='submit' className='btn btn-primary'>DEPOSIT</button>
                    </form>
                  </div>
                </Tab>
                <Tab eventKey="withdraw" title="Withdraw">
                  <div>
                    <br></br>
                    Do you want to withdraw your deposit?
                    <br></br>
                    <br></br>
                    <div>
                      <button type='submit' 
                      className='btn btn-primary'
                      onClick={ (e) => this.withdraw(e) }
                      >WITHDRAW</button>
                    </div>

                  </div>
                </Tab>
              </Tabs>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}

export default App;